<template>
  <div class="box">
    <div class="imgBox" :style="getBackStyle()" :id="info.fileId"></div>
    <div class="title" :title="info.fileName">{{ info.fileName }}</div>
  </div>
</template>

<script>
export default {
  name: "fileBox",
  props: {
    info: Object,
  },
  data() {
    return {
      backgroundStyle: {
        backgroundImage: `url(${require('@/assets/file/qt.png')})`,
        width: "100%",
        height: "100%",
        backgroundSize: 'cover', // 适应容器大小
        backgroundPosition: 'center' // 居中对齐
      }
    }
  },
  methods: {
    getBackStyle() {
      if (this.$config.supportFile.imgType.indexOf(this.info.fileType) !== -1) {
        this.backgroundStyle.backgroundImage = `url(${this.$util.getOpenFileUrl(this.info.fileId)})`;
      }
      //判断是否能找到对应图片
      let icon = this.$config.supportFile.fileToIconPngMap[this.info.fileType]
      if(icon!=null && icon!==""){
        this.backgroundStyle.backgroundImage = `url(${require('@/assets/file/'+icon)})`
      }
      return this.backgroundStyle;
    }
  }
}
</script>


<style scoped lang="scss">
.box {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;

  .title {
    z-index: 900;
    width: 100%;
    text-align: center;
    font-size: 12px;
    white-space: nowrap; /* 禁止换行 */
    overflow: hidden; /* 隐藏超出内容 */
    text-overflow: ellipsis; /* 使用省略号替代被隐藏的文本 */
  }

  .imgBox {
    position: relative; /* 父元素设为相对定位 */
    width: 100%;
    height: 100%;

    .fileType {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 18px;
    }
  }


}

.box:hover {
  .title {
    color: #29AAE3;
    text-decoration: underline;
  }
}
</style>